<template>
  <div class="modal" :class="cssClasses">
    <div class="modal-background" @click="toggleModal" />
    <div class="modal-content" :class="isFullscreen ? 'modal-fullscreen' : ''">
      <slot />
    </div>
    <div>
      <button class="modal-close is-large" aria-label="close" @click="toggleModal">
        <span class="close-label">Schließen</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicModal",
  props: {
    isModalActive: {
      type: Boolean,
      default: true,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["toggleModal"],
  computed: {
    cssClasses() {
      let classesString = ""
      if (this.isModalActive) {
        classesString += "is-active "
      }
      if (this.hasPadding) {
        classesString += "has-padding "
      }
      return classesString
    },
  },
  methods: {
    toggleModal() {
      this.$emit("toggleModal")
    },
  },
}
</script>

<style scoped lang="scss">
.modal-background {
  background-color: rgba(10, 10, 10, 0.66);
}

.close-label {
  font-size: 18px;
  color: white;
  top: 4px;
  right: 34px;
  position: absolute;
}

.modal-content {
  background-color: white;
  border-radius: 20px;
  max-height: none;

  .has-padding {
    padding: 60px 80px;
  }

  &.modal-fullscreen {
    padding: 10px 40px;
    width: 82%;
  }
}
</style>
